<template>
  <div :class="['select-none flex flex-col', { dragging: isDragging }]">
    <div v-if="showControls" :class="controlClasses">
      <div :class="[navClasses, leftNavClasses]" @click="leftScrollHandler">
        <IconChevron direction="left" class="size-1/2" />
      </div>
      <div :class="[navClasses, rightNavClasses]" @click="rightScrollHandler">
        <IconChevron direction="right" class="size-1/2" />
      </div>
    </div>
    <div
      class="flex overflow-x-scroll scrolling-touch items-start scrollbar-none"
      :class="shelfClasses"
      ref="shelfElement"
      @scroll="scrollHandler"
      @scrollend="scrollEndHandler"
      @mousedown="shelfStartDraggingHandler"
      @mouseup="shelfStopDraggingHandler"
      @mousemove="shelfMoveHandler"
      @mouseleave="shelfStopDraggingHandler"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn, useResizeObserver } from '@vueuse/core'

type range = {
  start: number
  end: number
}

const props = withDefaults(
  defineProps<{
    name: string
    step?: number
    wrapAround?: boolean
    position?:
      | 'top'
      | 'bottom'
      | 'middle-inset'
      | 'middle-outset'
      | 'top-left'
      | 'top-middle'
      | 'top-right'
      | 'bottom-left'
      | 'bottom-middle'
      | 'bottom-right'
    showControls?: boolean
    split?: boolean
    gapClass?:
      | 'gap-0'
      | 'gap-1'
      | 'gap-2'
      | 'gap-3'
      | 'gap-4'
      | 'gap-5'
      | 'gap-6'
      | 'gap-7'
      | 'gap-8'
      | 'gap-9'
      | 'gap-10'
      | 'gap-11'
      | 'gap-12'
      | 'gap-14'
      | 'gap-16'
  }>(),
  {
    step: 1,
    position: 'top-right',
    showControls: true,
    split: false,
    gapClass: 'gap-4'
  }
)

const leftScrollHandler = () => {
  updateBreakpoints()
  updateShelfDimensions()

  if (shelfElement.value == null || !leftScrollEnabled.value || !breakpoints.value.length) return

  const index = Math.max(
    -1,
    breakpoints.value.findLastIndex((bp) => currentView.value.left > bp.start) - props.step + 1
  )

  const bp = breakpoints.value[index === -1 && props.wrapAround ? breakpoints.value.length - 1 : Math.max(0, index)]

  if (!bp) return

  shelfElement.value.scrollTo({ left: bp.start, behavior: 'smooth' })
  shelfDimensions.value.scrollLeft = bp.start

  segmentTrack('Shelf Navigation', {
    to: index,
    shelf_id: props.name,
    direction: 'left',
    step: props.step
  })
}

const rightScrollHandler = () => {
  updateBreakpoints()
  updateShelfDimensions()

  if (shelfElement.value == null || !rightScrollEnabled.value || !breakpoints.value.length) return

  const index = Math.min(
    breakpoints.value.length - 1,
    breakpoints.value.findIndex((bp) => bp.end > currentView.value.left) + props.step
  )

  const bp = breakpoints.value[scrolledToEnd.value && props.wrapAround ? 0 : index]

  if (!bp) return

  shelfElement.value.scrollTo({ left: bp.start, behavior: 'smooth' })
  shelfDimensions.value.scrollLeft = bp.start

  segmentTrack('Shelf Navigation', {
    to: index,
    shelf_id: props.name,
    direction: 'right',
    step: props.step
  })
}

const shelfStartDraggingHandler = (e: MouseEvent) => {
  canStartDragging.value = true
  updateBreakpoints()
  updateShelfDimensions()

  e.preventDefault()

  if (!shelfElement.value) return

  dragStartX.value = e.pageX - shelfElement.value.offsetLeft
  dragStartLeft.value = shelfElement.value.scrollLeft
  dragScrollLeft.value = shelfElement.value.scrollLeft
}

const shelfStopDraggingHandler = (e: MouseEvent) => {
  e.preventDefault()

  isDragging.value = false
  canStartDragging.value = false

  if (!shelfElement.value || dragStartLeft.value == null) return

  shelfScrollTracker(shelfElement.value.scrollLeft, dragStartLeft.value)
  dragStartLeft.value = null
}

const shelfScrollTracker = useDebounceFn((to, from) => {
  segmentTrack('Shelf Scrolled', {
    shelf_id: props.name,
    from,
    to
  })
}, 500)

const shelfMoveHandler = (e: MouseEvent) => {
  e.preventDefault()
  if (!shelfElement.value || (!canStartDragging.value && !isDragging.value)) return

  isDragging.value = true

  const x = e.pageX - shelfElement.value.offsetLeft
  const scroll = x - dragStartX.value
  shelfElement.value.scrollLeft = dragScrollLeft.value - scroll
}

const scrollHandler = () => {
  if (!shelfElement.value) return

  shelfDimensions.value.scrollLeft = shelfElement.value.scrollLeft
  if (!isScrolling.value) dragStartLeft.value = shelfElement.value.scrollLeft

  isScrolling.value = true
}

const scrollEndHandler = () => {
  if (!isScrolling.value || !shelfElement.value) return

  shelfScrollTracker(shelfElement.value.scrollLeft, dragStartLeft.value)
  dragStartLeft.value = null
  isScrolling.value = false
}

const updateBreakpoints = () => {
  if (shelfElement.value == null) return

  const b = []

  b.length = 0
  for (const child of shelfElement.value.children) {
    const c = child as HTMLElement
    b.push({ start: c.offsetLeft, end: c.offsetLeft + c.clientWidth })
  }

  breakpoints.value = b
}

const updateShelfDimensions = () => {
  shelfDimensions.value.clientWidth = shelfElement.value?.clientWidth ?? 0
  shelfDimensions.value.scrollLeft = shelfElement.value?.scrollLeft ?? 0
  shelfDimensions.value.scrollWidth = shelfElement.value?.scrollWidth ?? 0
}

const currentView = computed(() => {
  const view = {
    width: 0,
    left: 0,
    right: 0
  }

  const visibleWidth = shelfDimensions.value.clientWidth

  view.left = shelfDimensions.value.scrollLeft
  view.right = view.left + visibleWidth
  view.width = shelfDimensions.value.scrollWidth ?? 0

  return view
})

const leftScrollEnabled = computed(
  () => props.wrapAround || breakpoints.value.find((bp) => currentView.value.left > bp.start) != null
)

const rightScrollEnabled = computed(
  () => props.wrapAround || breakpoints.value.findLast((bp) => currentView.value.right < bp.end) != null
)

const shelfClasses = computed(() => {
  const classes: Array<string | object> = [
    { 'hover:cursor-grab': !isDragging.value, 'cursor-grabbing': isDragging.value }
  ]

  if (props.position === 'middle-outset' && props.showControls) {
    classes.push('mx-12')
  }

  classes.push(props.gapClass)

  return classes
})

const navClasses = computed(() => {
  const classes = [
    'flex',
    'size-8',
    'bg-primary-50',
    'cursor-pointer',
    '[&:is(.disabled)]:cursor-not-allowed',
    '[&:is(.disabled)]:text-gray-200',
    '[&:not(.disabled)]:hover:border-primary',
    'border-2',
    'border-transparent',
    'rounded-full',
    'items-center',
    'justify-center'
  ]

  if (['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('absolute')
  }

  if (props.position === 'middle-inset') {
    classes.push('opacity-50', 'hover:opacity-100')
  }

  return classes
})

const leftNavClasses = computed(() => {
  const classes: Array<object | string> = [{ disabled: !leftScrollEnabled.value }]

  if (['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('left-2')
  }

  return classes
})

const rightNavClasses = computed(() => {
  const classes: Array<object | string> = [{ disabled: !rightScrollEnabled.value }]

  if (['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('right-2')
  }

  return classes
})

const controlClasses = computed(() => {
  const positionClasses = {
    top: ['mb-2'],
    bottom: ['mt-2', 'order-last'],
    'middle-inset': ['absolute', 'z-10', 'top-[calc(50%-1rem)]'],
    'middle-outset': ['absolute', 'z-10', 'top-[calc(50%-1rem)]'],
    'top-left': ['mb-2', 'justify-start'],
    'top-middle': ['mb-2', 'mx-auto'],
    'top-right': ['mb-2', 'justify-end'],
    'bottom-left': ['mt-2', 'justify-start', 'order-last'],
    'bottom-middle': ['mt-2', 'mx-auto', 'order-last'],
    'bottom-right': ['mt-2', 'justify-end', 'order-last']
  }

  const classes = []

  if (!['middle-inset', 'middle-outset'].includes(props.position)) {
    classes.push('flex', 'gap-3')
  } else {
    classes.push('w-full')
  }

  if (props.split) classes.push('justify-between')

  classes.push(...positionClasses[props.position])

  return classes
})

const scrolledToEnd = computed(() => currentView.value.right >= currentView.value.width)

const shelfElement = ref<HTMLDivElement>()
const isDragging = ref(false)
const isScrolling = ref(false)
const canStartDragging = ref(false)
const dragStartX = ref(0)
const dragStartLeft = ref<Number | null>(null)
const dragScrollLeft = ref(0)
const shelfDimensions = ref({
  clientWidth: 0,
  scrollLeft: 0,
  scrollWidth: 0
})
const breakpoints = ref<range[]>([])

useResizeObserver(shelfElement, () => {
  updateShelfDimensions()
})

onMounted(() => {
  updateBreakpoints()
  updateShelfDimensions()
})
</script>

<style lang="css" scoped>
.dragging :deep(a) {
  pointer-events: none;
}
</style>
